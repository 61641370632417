exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mizuki-koutsu-index-tsx": () => import("./../../../src/pages/mizuki-koutsu/index.tsx" /* webpackChunkName: "component---src-pages-mizuki-koutsu-index-tsx" */),
  "component---src-pages-mizuki-koutsu-inquiry-tsx": () => import("./../../../src/pages/mizuki-koutsu/inquiry.tsx" /* webpackChunkName: "component---src-pages-mizuki-koutsu-inquiry-tsx" */),
  "component---src-pages-mizuki-koutsu-inquirycomplete-tsx": () => import("./../../../src/pages/mizuki-koutsu/inquirycomplete.tsx" /* webpackChunkName: "component---src-pages-mizuki-koutsu-inquirycomplete-tsx" */),
  "component---src-pages-mizuki-koutsu-inquiryconf-tsx": () => import("./../../../src/pages/mizuki-koutsu/inquiryconf.tsx" /* webpackChunkName: "component---src-pages-mizuki-koutsu-inquiryconf-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-rule-tsx": () => import("./../../../src/pages/rule.tsx" /* webpackChunkName: "component---src-pages-rule-tsx" */),
  "component---src-pages-verybest-koutsu-index-tsx": () => import("./../../../src/pages/verybest-koutsu/index.tsx" /* webpackChunkName: "component---src-pages-verybest-koutsu-index-tsx" */),
  "component---src-pages-verybest-koutsu-inquiry-tsx": () => import("./../../../src/pages/verybest-koutsu/inquiry.tsx" /* webpackChunkName: "component---src-pages-verybest-koutsu-inquiry-tsx" */),
  "component---src-pages-verybest-koutsu-inquirycomplete-tsx": () => import("./../../../src/pages/verybest-koutsu/inquirycomplete.tsx" /* webpackChunkName: "component---src-pages-verybest-koutsu-inquirycomplete-tsx" */),
  "component---src-pages-verybest-koutsu-inquiryconf-tsx": () => import("./../../../src/pages/verybest-koutsu/inquiryconf.tsx" /* webpackChunkName: "component---src-pages-verybest-koutsu-inquiryconf-tsx" */),
  "component---src-templates-telconversion-tsx": () => import("./../../../src/templates/telconversion.tsx" /* webpackChunkName: "component---src-templates-telconversion-tsx" */)
}

